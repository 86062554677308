import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/products.css"
import ProductsPastile from "../components/productsPastile"

import img1 from "../images/product1/44.webp"
import img2 from "../images/product2/75.jpg"
import img3 from "../images/product3/201.webp"
import img4 from "../images/product4/167.jpg"
import img5 from "../images/product5/224.jpg"
import img6 from "../images/product6/281.jpg"
import img8 from "../images/product9/DSC_0030.webp"
import img11 from "../images/product12/DSC_0001.webp"
import img12 from "../images/product13/DSC_0001.webp"
import img14 from "../images/jaccuzi/1.jpeg"
import img15 from "../images/beczka-po-saunie/1.jpeg"
import img16 from "../images/product3/119.jpg"
import img17 from "../images/domek-ogrodowy-4-m-na-4-m-45mm/0001.webp"
import img18 from "../images/domek-letniskowy-5m-x-4m-45mm/0001.webp"
import img19 from '../images/balia-swierkowa-165x125-45mm-grzalka/0001.webp'
import img20 from '../images/jacuzzi-200cm-6-osob/0007.webp'
import img21 from '../images/jacuzzi-ogrodowe-210-x-193/0005.webp'
import img22 from '../images/sauna-ogrodowa-beczka-350-cm/0004.webp'

const Products = () => (
  <Layout>
    <SEO title="Produkty" description={SEO.description} />
    <div className="banner">
      <div className="bannerPlate">
        <p>Drew-Haus | Produkty</p>
      </div>
    </div>
    <div className="productsPlate">
      <div className="subCategory">
        <p>Domki ogrodowe</p>
      </div>
      <div className="PlateRow">
        <ProductsPastile
          title="Domek ogrodowy 3x2m"
          number="/domek-ogrodowy-3-m-na-2-m"
          img={img1}
        />
        <ProductsPastile
          title="Domek ogrodowy 3x3m"
          number="/domek-ogrodowy-3-m-na-3-m"
          img={img2}
        />
        <ProductsPastile
          title="Domek ogrodowy 4x3,8m + zadaszenie"
          number="/domek-ogrodowy-4-m-na-3-m"
          img={img3}
        />
      </div>
      <div className="PlateRow">
        <ProductsPastile
          title="Domek ogrodowy 4x4m"
          number="/domek-ogrodowy-4-m-na-4-m"
          img={img16}
        />
      </div>
      <div className="subCategory">
        <p>Domki letniskowe</p>
      </div>
      <div className="PlateRow">
      <ProductsPastile
          title="Domek letniskowy 4x4m - balik 45mm"
          number="/domek-ogrodowy-4-m-na-4-m-45mm"
          img={img17}
        />
        <ProductsPastile
          title="Domek letniskowy 5x3m"
          number="/domek-letniskowy-5-m-na-3-m"
          img={img4}
        />
        <ProductsPastile
          title="Domek letniskowy 5x4m"
          number="/domek-letniskowy-5-m-na-4-m"
          img={img5}
        />
      </div>
      <div className="PlateRow">
        <ProductsPastile
          title="Domek letniskowy 5x4m - balik 45mm"
          number="/domek-letniskowy-5-m-na-4-m-45mm"
          img={img18}
        />
        <ProductsPastile
          title="Domek letniskowy 5x5m - balik 45mm"
          number="/domek-letniskowy-5-m-na-5-m-45mm"
          img={img18}
        />
        <ProductsPastile
          title="Domek letniskowy 6x4m"
          number="/domek-letniskowy-6-m-na-4-m"
          img={img6}
        />
      </div>
      <div className="subCategory">
        <p>Sauny ogrodowe</p>
      </div>
      <div className="PlateRow">
        <ProductsPastile
          title="Sauna ogrodowa"
          number="/sauna-ogrodowa"
          img={img8}
        />
        <ProductsPastile
          title="Sauna ogrodowa z przedsionkiem"
          number="/sauna-ogrodowa-z-przedsionkiem"
          img={img11}
        />
        <ProductsPastile
          title="Balia świerkowa 165cm x 125cm"
          number="/balia-swierkowa-165cm-x-125cm"
          img={img19}
        />
      </div>
      <div className="PlateRow">
        <ProductsPastile
          title="Sauna ogrodowa 200cm"
          number="/sauna-ogrodowa-200-cm"
          img={img12}
        />
        <ProductsPastile
          title="Sauna ogrodowa 350cm"
          number="/sauna-ogrodowa-350-cm"
          img={img22}
        />
        <ProductsPastile
          title="Beczka po saunie"
          number="/beczka-po-saunie"
          img={img15}
        />
      </div>
      <div className="subCategory">
        <p>Jaccuzi</p>
      </div>
      <div className="PlateRow">
        <ProductsPastile
          title="Jacuzzi 12 dysz AIR LED"
          number="/jacuzzi-12-dysz-led"
          img={img14}
        />
        <ProductsPastile
          title="Jacuzzi ogrodowe 200cm okrągłe"
          number="/jacuzzi-okragle-200cm"
          img={img20}
        />
        <ProductsPastile
          title="Jacuzzi ogrodowe 210cm x 192cm"
          number="/jacuzzi-ogrodowe-210-cm-x-193-cm"
          img={img21}
        />
      </div>
    </div>
    <div className="productsInfoBanner">
      <p
        className="infobanner"
        data-sal="slide-right"
        data-sal-delay="600"
        data-sal-easing="ease"
      >
        Jesteś zainteresowany lub masz jakieś pytania? Zadzwoń!
      </p>
    </div>
  </Layout>
)

export default Products
